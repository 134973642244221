function numberWithCommas(digits) {
  if (!digits) return 0
  var parts = digits.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

const educationLevels = [
  'Less than Secondary School',
  'Secondary School',
  'NCE',
  'OND',
  'HND',
  "Bachelor's Degree",
  'Post Graduate Diploma',
  'Masters Degree',
  'Ph.D'
]

const martialStatus = [
  '__Choose status__',
  'Single',
  'Married',
  'Separated',
  'Widow'
]

const consultationRemarksSkilledWorker = [
  'You need a minimum of 1 year relevant work experience.',
  'You need a minimum of 2 years relevant work experience.',
  'Sit for and pass your English test (IELTS) very well, at least CLB level 5.',
  'Sit for and pass your English test (IELTS) very well, at least CLB level 7.',
  'Sit for and pass your English test (IELTS) very well, at least CLB level 8.',
  'Sit for and pass your English test (IELTS) very well, at least CLB level 9.',
  // 'Obtain your Educational credential Assessment of all your degrees, Diploma certificate.',
  'Obtain your educational credential (certificate & transcript) for assessment at World Education Service (www.wes.org/ca)',
  'Obtain a document to prove your relevant work experience from your employer',
  "Let's help you secure an arranged Employment in Canada after getting to the pool.",
  'Your score on the selection factor must not be less than 67 points.',
  'You must provide proof of enough funds.',
  'You should encourage your spouse to write and pass the IELTS test at least up to CLB Level 4',
  'You will be able to score  more points if you submit your spouse degree, Diploma certificate for assessment by WES.',
  'Save money in your bank account (just in case)'
]

const consultationRemarksSkilledTrader = [
  'You need an acceptable certificate or Diploma in your trade/ vocation (ND, NABTEB, City Guild  )',
  'Obtain your Federal Trade Test up to grade 1 Level',
  'Obtain your Apprenticeship Certificate. (Freedom) ',
  'Possession at least 6 Credit pass in your ‘O’ level subject (GCE, WAEC, NECO Etc.)',
  // 'Obtain an acceptable proof of your relevant work experience in your trade acceptable proof of your relevant in your Trade (At least  2years within in the past 5years)',
  'Obtain your educational credential (certificate & transcript) for assessment at World Education Service (www.wes.org/ca) if applicable to you to obtain more points',
  'Obtain a document to acceptably prove that you have at least 2 years of relevant work experience within the last 5 years',
  'Sit for and pass your English test (IELTS) at least CLB Level 5',
  'Cooperate with Jonathan King Ltd to obtain your Qualification certificate of your Trade from any province or territory in Canada'
]

export {
  numberWithCommas,
  educationLevels,
  martialStatus,
  consultationRemarksSkilledTrader,
  consultationRemarksSkilledWorker
}
